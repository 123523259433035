import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import { DialogComponent } from "@syncfusion/ej2-react-popups";
import { Component } from "react";
import { FormRenderProps } from "react-final-form";
import RAFChoiceOption from "../../../../RAFComponents/Inputs/RAFChoiceOption";
import RAFDatePicker from "../../../../RAFComponents/Inputs/RAFDatePicker";
import RAFDropdownCC from "../../../../RAFComponents/Inputs/RAFDropdownCC";
import RAFForm, { Condition } from "../../../../RAFComponents/Inputs/RAFForm";
import RAFAttributeRelatedListProvider from "../../../../RAFComponents/Providers/RAFAttributeRelatedListProvider";
import { showSuccessToast } from "../../../../RAFComponents/Utility/RAFToastComponent";
import {
    hideProgress,
    showProgress
} from "../../../../RAFComponents/helpers/AppHelper";
import { getCreateComponent } from "../../../../RAFComponents/helpers/RAFMenuHelper";
import {
    IDialogProps,
    RAFFormComponentProps,
    deepEqual,
    getSaveRequest,
    isNotNullAndUndefined,
    isNullOrUndefined
} from "../../../../RAFComponents/helpers/utils";
import * as repositoryActions from "../../../../RAFComponents/store/actions/repositoryActions";
import RAFEntityProvider from "../../../../RAFModules/Common/Providers/RAFEntityProvider";
import RAFPageLayoutProvider from "../../../../RAFModules/Common/Providers/RAFPageLayoutProvider";
import RAFPageLayout from "../../../../RAFModules/Common/RAFPageLayout";
import {
    ContentType,
    RAFLayout
} from "../../../../constants/Common/Constants";
import {
    RAFBPStatus,
    RAFDeadlineCalculation
} from "../../../../constants/Common/RMConstants";
import { getURLPrefixByModuleName } from "../../../../helpers/Common/URLPrefixByModuleName";
import { NavigateParamsProps } from "../../../../router";
import {
    getBPTemplatesByEntityName,
    getEntityByName
} from "../../../helpers/RMutils";
import { BusinessProcessStepTemplateRow } from "../StepTemplate/BusinessProcessStepTemplateRow";
import { BusinessProcessTemplateRow } from "../Template/BusinessProcessTemplateRow";
import { StartProcessAndSaveProcessSteps } from "../helpers/ProcessHelper";
import { BusinessProcessRow } from "./BusinessProcessRow";

interface IState {
    bpTemplates?: BusinessProcessTemplateRow[];
    showStartProcessContent?: boolean;
    bpTemplate?: BusinessProcessTemplateRow;
    objRow?: any;
    relatedTo?: string;
    relatedToType?: string;
    relatedToUID?: string;
    isActive?: boolean;
    pageLayoutUID?: string;
    showCreateComponent?: boolean;
    showCreateComponentWithProcess?: boolean;
    isProcessRequired?: boolean;
}

interface IProps {
    moduleName?: string;
    onProcessStart?: (entityId) => void;
    createComponent?: any;
}

class RAFCreateFormData extends Component<
    IProps & IDialogProps & RAFFormComponentProps & NavigateParamsProps,
    IState
> {
    public processrafForm: FormRenderProps | null;
    public initialrafFForm: FormRenderProps | null;

    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = {
            isActive: false,
            bpTemplate: {},
            bpTemplates: [],
            showStartProcessContent: false,
            objRow: {},
            relatedTo: null,
            relatedToType: null,
            relatedToUID: null,
            pageLayoutUID: null,
            showCreateComponent: false,
            isProcessRequired: false,
            showCreateComponentWithProcess: false,
        };
    }

    componentDidMount = () => {
        this._isMounted = true;
        this.refresh(this.props.isActive);
    };

    refresh = (isActive: boolean) => {
        if (isActive === true) {
            let progressDiv = showProgress(`#dlg_create_${this.props.moduleName}`);
            if (this._isMounted) {
                getEntityByName(this.props.moduleName).then((entity) => {
                    let enableProcess =
                        isNotNullAndUndefined(entity.EntitySettings) &&
                            isNotNullAndUndefined(entity.EntitySettings.EnableProcess)
                            ? entity.EntitySettings.EnableProcess
                            : false;
                    let isProcessRequired =
                        isNotNullAndUndefined(entity.EntitySettings) &&
                            isNotNullAndUndefined(entity.EntitySettings.IsProcessRequired)
                            ? entity.EntitySettings.IsProcessRequired
                            : false;
                    if (enableProcess === true) {
                        getBPTemplatesByEntityName(entity.EntityName).then(
                            (bpTemplates) => {
                                hideProgress(progressDiv);
                                if (isNotNullAndUndefined(bpTemplates) && bpTemplates.length > 0) {
                                    this.setState({ bpTemplates, isActive, isProcessRequired });
                                    // if (bpTemplates.length === 1) {
                                    //     this.setState({
                                    //         bpTemplates,
                                    //         bpTemplate: bpTemplates[0],
                                    //         isActive,
                                    //         isProcessRequired,
                                    //         pageLayoutUID: bpTemplates[0].PageLayoutUID,
                                    //         showCreateComponentWithProcess: isNullOrUndefined(
                                    //             bpTemplates[0].PageLayoutUID
                                    //         )
                                    //             ? true
                                    //             : false,
                                    //     });
                                    // } else if (bpTemplates.length > 1) {
                                    //     this.setState({ bpTemplates, isActive, isProcessRequired });
                                    // }
                                } else {
                                    this.setState({
                                        bpTemplates: [],
                                        bpTemplate: null,
                                        isActive,
                                        showCreateComponent: true,
                                        isProcessRequired,
                                    });
                                }
                            }
                        );
                    } else {
                        hideProgress(progressDiv);
                        this.setState({
                            bpTemplates: [],
                            bpTemplate: null,
                            isActive,
                            showCreateComponent: true,
                            isProcessRequired,
                        });
                    }
                });
            }
        }
    };

    componentDidUpdate = (prevProps/*, prevState*/) => {
        if (!deepEqual(prevProps.isActive, this.props.isActive)) {
            this.refresh(this.props.isActive);
        }
    };

    componentWillUnmount() {
        this._isMounted = false;
    }

    onSubmitProcessTemplate = (value) => {
        let bPTemplateRow: BusinessProcessTemplateRow = value;
        let progressDiv = showProgress(`#dlg_create_${this.props.moduleName}`, true);

        let { relatedTo, relatedToType, relatedToUID } = this.state;
        StartProcessAndSaveProcessSteps(
            {
                bpRelatedTo: relatedTo,
                bpRelatedToType: relatedToType,
                bpRelatedToUID: relatedToUID,
            },
            bPTemplateRow
        ).then((bpResponse) => {
            hideProgress(progressDiv);
            this.onProcessStart(bpResponse.bpRow, bpResponse.bpStepTemplateRows);
        });
    };

    onProcessStart = (
        bpRow: BusinessProcessRow,
        bpStepTemplateRows: BusinessProcessStepTemplateRow[]
    ) => {
        if (isNotNullAndUndefined(bpRow) && isNotNullAndUndefined(this.props.moduleName)) {
            let objValues = this.state.objRow;
            let url = getURLPrefixByModuleName(this.props.moduleName) + "/Save";
            objValues.ProcessStatus =
                isNotNullAndUndefined(bpStepTemplateRows) &&
                    isNotNullAndUndefined(bpStepTemplateRows[0])
                    ? bpStepTemplateRows[0].Title
                    : RAFBPStatus.InProgress;
            objValues.BusinessProcess = bpRow.ProcessName;
            objValues.BusinessProcessUID = bpRow.UID;
            let progressDiv = showProgress(`#dlg_create_${this.props.moduleName}`, true);
            repositoryActions
                .postDataAndGetResponse(
                    url,
                    getSaveRequest(objValues, objValues.UID),
                    { ...this.props },
                    ContentType.applicationJson
                )
                .then((response) => {
                    hideProgress(progressDiv);
                    if (
                        isNotNullAndUndefined(response) &&
                        isNotNullAndUndefined(response.data)
                    ) {
                        if (isNotNullAndUndefined(response.data.EntityId)) {
                            if (this._isMounted) {
                                this.setState({ showStartProcessContent: false }, () => {
                                    if (this.props.onProcessStart)
                                        this.props.onProcessStart(objValues.UID);
                                    else if (this.props.onSave)
                                        this.props.onSave(
                                            this.state.relatedToUID,
                                            this.state.relatedTo
                                        );
                                });
                            }
                        } else if (
                            response.data.Error !== null &&
                            response.data.Error !== undefined &&
                            response.data.Error !== ""
                        ) {
                            //console.log("Error on save", response.Error);
                        }
                    }
                })
                .catch((error) => error);
        }
    };

    // getModuleName = (moduleName?: string) => {
    //     let outVal = "";
    //     switch (moduleName) {
    //         case RAFEntityName.Deal:
    //             outVal = "Deal";
    //             break;
    //         case RAFEntityName.Ticket:
    //             outVal = "Ticket";
    //             break;
    //         case RAFEntityName.Contact:
    //             outVal = "Contact";
    //             break;
    //         case RAFEntityName.Account:
    //             outVal = "Account";
    //             break;
    //         default:
    //             break;
    //     }
    //     return outVal;
    // };

    onSubmitInitialForm = (value) => {
        let { bpTemplate } = this.state;
        let objValue = value["FormData"];
        let progressDiv = showProgress(`#dlg_create_${this.props.moduleName}`);
        let url = getURLPrefixByModuleName(this.props.moduleName) + "/Save";
        let businessProcess = bpTemplate.ProcessName;
        let businessProcessUID = bpTemplate.UID;
        repositoryActions
            .postDataAndGetResponse(
                url,
                getSaveRequest(objValue, null),
                null,
                ContentType.applicationJson
            )
            .then((response) => {
                hideProgress(progressDiv);
                if (
                    isNotNullAndUndefined(response) &&
                    isNotNullAndUndefined(response.data)
                ) {
                    if (
                        response.data.EntityId !== null &&
                        response.data.EntityId !== undefined &&
                        response.data.EntityId !== ""
                    ) {
                        if (
                            isNotNullAndUndefined(businessProcess) &&
                            isNotNullAndUndefined(businessProcessUID)
                        ) {
                            showSuccessToast("Saved successfully");
                            objValue.BusinessProcess = businessProcess;
                            objValue.BusinessProcessUID = businessProcessUID;
                            objValue.UID = response.data.EntityId;

                            this.setState(
                                {
                                    relatedTo: response.data.ObjectName,
                                    relatedToUID: response.data.EntityId,
                                    relatedToType: this.props.moduleName,
                                    objRow: objValue,
                                },
                                () => {
                                    if (
                                        isNotNullAndUndefined(
                                            bpTemplate.ProcessDeadlineCalculation
                                        ) &&
                                        bpTemplate.ProcessDeadlineCalculation ===
                                        RAFDeadlineCalculation.PromptUserWhenRun
                                    ) {
                                        this.startProcessDialogOpen(bpTemplate);
                                    } else {
                                        let progressDiv = showProgress(`#dlg_create_${this.props.moduleName}`, true);
                                        StartProcessAndSaveProcessSteps(
                                            {
                                                bpRelatedTo: response.data.ObjectName,
                                                bpRelatedToType: this.props.moduleName,
                                                bpRelatedToUID: response.data.EntityId,
                                            },
                                            bpTemplate
                                        ).then((bpResponse) => {
                                            hideProgress(progressDiv);
                                            this.onProcessStart(
                                                bpResponse.bpRow,
                                                bpResponse.bpStepTemplateRows
                                            );
                                        });
                                    }
                                }
                            );
                        } else {
                            /*showSuccessToast("Saved successfully");
                                          let allViews: RAFViewRow[] = JSON.parse(getSessionStorage(StorageKey.allViews_modulename + this.props.moduleName, true));
                                          let recentlyCreatedView: RAFViewRow = allViews && allViews.find(x => x.ViewName === 'recently_created');
                                          if (isNotNullAndUndefined(recentlyCreatedView)) {
                                              setSessionStorage(StorageKey.recentViewId_modulename + this.props.moduleName, true, JSON.stringify({ viewId: recentlyCreatedView.UID, viewName: recentlyCreatedView.DisplayName }));
                                          }
                                          if (this._isMounted) {
                                              this.setState({ isActive: false }, () => {
                                                  if (this.props.onSave)
                                                      this.props.onSave(response.data.EntityId, response.data.ObjectName);
                                              });
                                          }*/
                        }
                    } else if (
                        response.data.Error !== null &&
                        response.data.Error !== undefined &&
                        response.data.Error !== ""
                    ) {
                        //console.log("Error on save", response.data.Error);
                    }
                }
            })
            .catch((error) => error);
    };

    private startProcessDialogOpen(bpTemplate): void {
        this.setState({ showStartProcessContent: true, bpTemplate }, () => {
            document.body.classList.add("overflow-hidden");
        });
    }

    private startProcessDialogClose(): void {
        this.setState({ showStartProcessContent: false }, () => {
            //let allViews: RAFViewRow[] = JSON.parse(getSessionStorage(StorageKey.allViews_modulename + this.moduleName, true));
            //let recentlyCreatedView: RAFViewRow = allViews && allViews.find(x => x.ViewName === 'recently_created');
            //if (isNotNullAndUndefined(recentlyCreatedView)) {
            //    setSessionStorage(StorageKey.recentViewId_modulename + this.moduleName, true, JSON.stringify({ viewId: recentlyCreatedView.UID, viewName: recentlyCreatedView.DisplayName }));
            //}
            //if (this._isMounted) {
            //    this.setState({ isActive: false }, () => {
            //        if (this.props.onSave)
            //            this.props.onSave(this.state.relatedToUID, this.state.relatedTo);
            //    });
            //}
            document.body.classList.remove("overflow-hidden");
        });
    }

    startProcessContent() {
        let { bpTemplate } = this.state;
        if (
            this.state.showStartProcessContent &&
            this.state.showStartProcessContent === true
        ) {
            return (
                <div id="startBusinessProcessForm">
                    <RAFForm
                        type={BusinessProcessTemplateRow}
                        initialValues={bpTemplate}
                        formRef={(g) => (this.processrafForm = g)}
                        layout={RAFLayout.TwoColumnLayout}
                        onSubmit={this.onSubmitProcessTemplate}
                    >
                        <div className="dlg-new-style">
                            <div className="e-dlg-headerContent justify-content-between">
                                <div className="d-flex align-items-center w-100">
                                    <div className="e-dlg-header" id="_title">
                                        {" "}
                                        {bpTemplate.ProcessName}
                                    </div>
                                </div>
                                <ButtonComponent
                                    type="button"
                                    cssClass="primary-custom-button"
                                    iconCss="fas fa-xmark"
                                    onClick={() => this.startProcessDialogClose()}
                                    id={`btn_bp_xmark_${this.props.moduleName}`}
                                ></ButtonComponent>
                            </div>
                            <div className="e-dlg-body-content pt-3 h-100 overflow-auto">
                                <Condition when="ProcessDeadlineCalculation" is={"Not set"}>
                                    <RAFDatePicker<BusinessProcessTemplateRow>
                                        field="DueDate"
                                        label="Do you want to set deadline?"
                                    />
                                </Condition>
                                <Condition when="ProcessDeadlineCalculation" is={null}>
                                    <RAFDatePicker<BusinessProcessTemplateRow>
                                        field="DueDate"
                                        label="Do you want to set deadline?"
                                    />
                                </Condition>
                            </div>
                            <div className="e-dlg-footerContent ">
                                <ButtonComponent
                                    type="submit"
                                    className="me-2"
                                    cssClass="e-flat e-info"
                                    id={`btn_bp_start_${this.props.moduleName}`}
                                >
                                    Start
                                </ButtonComponent>
                                <ButtonComponent
                                    type="button"
                                    cssClass="e-flat"
                                    onClick={() => this.startProcessDialogClose()}
                                    id={`btn_bp_cancel_${this.props.moduleName}`}
                                >
                                    Cancel
                                </ButtonComponent>
                            </div>
                        </div>
                    </RAFForm>
                </div>
            );
        } else {
            return <div></div>;
        }
    }

    onTemplateChanged = (label, value) => {
        let { bpTemplates } = this.state;
        let bpTemplate = isNotNullAndUndefined(bpTemplates)
            ? bpTemplates.find((x) => x.UID === value)
            : null;
        if (isNotNullAndUndefined(bpTemplate)) {
            //this.setState({ pageLayoutUID: bpTemplate.PageLayoutUID, bpTemplate });

            if (isNotNullAndUndefined(bpTemplate.PageLayoutUID)) {
                this.setState({ pageLayoutUID: bpTemplate.PageLayoutUID, bpTemplate });
            } else {
                this.setState({
                    pageLayoutUID: null,
                    bpTemplate,
                    showCreateComponentWithProcess: true,
                });
            }
        }
    };

    skipCreateProcess = () => {
        this.setState({
            bpTemplates: [],
            bpTemplate: null,
            isActive: true,
            showCreateComponent: true,
        });
    };

    render() {
        let {
            bpTemplates,
            isActive,
            bpTemplate,
            pageLayoutUID,
            showCreateComponent,
            showCreateComponentWithProcess,
            showStartProcessContent,
            isProcessRequired,
        } = this.state;
        let { moduleName } = this.props;
        let createComponent = isNotNullAndUndefined(this.props.createComponent) ? this.props.createComponent : getCreateComponent(moduleName);

        let CreateForm = createComponent;

        if (isActive === true) {
            return (
                <div className="h-100" id="createInitialFormDiv">
                    {/* {isNotNullAndUndefined(bpTemplates) &&
                        bpTemplates.length === 1 &&
                        isNotNullAndUndefined(bpTemplate) &&
                        isNotNullAndUndefined(pageLayoutUID) &&
                        showCreateComponentWithProcess === false && (
                            <div id="intialProcessForm">
                                <RAFForm
                                    formRef={(g) => (this.initialrafFForm = g)}
                                    layout={RAFLayout.TwoColumnLayout}
                                    onSubmit={this.onSubmitInitialForm}
                                >
                                    <div className="e-dlg-body-content">
                                        <RAFEntityProvider moduleName={moduleName}>
                                            <RAFAttributeRelatedListProvider moduleName={moduleName}>
                                                <RAFPageLayoutProvider
                                                    pagelayoutUID={bpTemplate.PageLayoutUID}
                                                    prefix={"FormData"}
                                                >
                                                    <RAFPageLayout />
                                                </RAFPageLayoutProvider>
                                            </RAFAttributeRelatedListProvider>
                                        </RAFEntityProvider>
                                    </div>
                                    <div className="e-dlg-footerContent ">
                                        {isProcessRequired === false && (
                                            <ButtonComponent
                                                type="button"
                                                cssClass="form-custom-button e-flat e-info"
                                                onClick={() => this.skipCreateProcess()}
                                            >
                                                Skip
                                            </ButtonComponent>
                                        )}
                                        <ButtonComponent
                                            type="button"
                                            cssClass="form-custom-button e-flat e-info"
                                            onClick={() =>
                                                this.initialrafFForm &&
                                                this.initialrafFForm.form.submit()
                                            }
                                        >
                                            Save
                                        </ButtonComponent>
                                        <ButtonComponent
                                            type="button"
                                            cssClass="form-custom-button"
                                            onClick={this.props.onClose}
                                        >
                                            Cancel
                                        </ButtonComponent>
                                    </div>
                                </RAFForm>
                            </div>
                        )}
                    {isNotNullAndUndefined(bpTemplates) &&
                        bpTemplates.length === 1 &&
                        isNotNullAndUndefined(bpTemplate) &&
                        isNullOrUndefined(pageLayoutUID) &&
                        showCreateComponentWithProcess && (
                            <div>
                                <CreateForm
                                    as2={this.props.createComponent}
                                    bpTemplate={bpTemplate}
                                    isActive
                                    onSave={() => this.props.onSave()}
                                    onClose={() => this.props.onClose()}
                                    onProcessStart={(processId) =>
                                        this.props.onProcessStart(processId)
                                    }
                                />
                            </div>
                        )} */}
                    {isNotNullAndUndefined(bpTemplates) && bpTemplates.length > 0 && (
                        <div className="h-100">
                            <div className="e-dlg-body-content">
                                <RAFForm>
                                    {bpTemplates &&
                                        bpTemplates.length > 0 &&
                                        isNullOrUndefined(pageLayoutUID) &&
                                        showCreateComponentWithProcess === false && (
                                            <RAFDropdownCC
                                                field="TemplateUID"
                                                label={"Choose Process"}
                                                placeholder={""}
                                                required
                                                showClearButton
                                                allowFiltering
                                                onChanged={(label, value) => {
                                                    this.onTemplateChanged(label, value);
                                                }}
                                            >
                                                {bpTemplates.map((item) => {
                                                    return (
                                                        <RAFChoiceOption
                                                            key={item.UID}
                                                            label={item.ProcessName}
                                                            value={item.UID}
                                                        />
                                                    );
                                                })}
                                            </RAFDropdownCC>
                                        )}
                                    <div className="e-dlg-footerContent ">
                                        {isProcessRequired === false && (
                                            <ButtonComponent
                                                type="button"
                                                cssClass="form-custom-button e-flat e-info"
                                                onClick={() => this.skipCreateProcess()}
                                                id={`btn_skip_${this.props.moduleName}`}
                                            >
                                                Skip
                                            </ButtonComponent>
                                        )}
                                    </div>
                                </RAFForm>
                                {isNotNullAndUndefined(pageLayoutUID) &&
                                    showCreateComponentWithProcess === false && (
                                        <RAFForm
                                            formRef={(g) => (this.initialrafFForm = g)}
                                            layout={RAFLayout.TwoColumnLayout}
                                            onSubmit={this.onSubmitInitialForm}
                                        >
                                            <RAFEntityProvider moduleName={moduleName}>
                                                <RAFAttributeRelatedListProvider
                                                    moduleName={moduleName}
                                                >
                                                    <RAFPageLayoutProvider
                                                        pagelayoutUID={pageLayoutUID}
                                                        prefix={"FormData"}
                                                    >
                                                        <RAFPageLayout />
                                                    </RAFPageLayoutProvider>
                                                </RAFAttributeRelatedListProvider>
                                            </RAFEntityProvider>
                                            <div className="e-dlg-footerContent ">
                                                <ButtonComponent
                                                    type="button"
                                                    cssClass="form-custom-button e-flat e-info"
                                                    onClick={() =>
                                                        this.initialrafFForm &&
                                                        this.initialrafFForm.form.submit()
                                                    }
                                                    id={`btn_save_create_${this.props.moduleName}_withProcess`}
                                                >
                                                    Save
                                                </ButtonComponent>
                                                <ButtonComponent
                                                    type="button"
                                                    cssClass="form-custom-button"
                                                    onClick={this.props.onClose}
                                                    id={`btn_cancel_create_${this.props.moduleName}_withProcess`}
                                                >
                                                    Cancel
                                                </ButtonComponent>
                                            </div>
                                        </RAFForm>
                                    )}
                                {isNullOrUndefined(pageLayoutUID) &&
                                    showCreateComponentWithProcess && (
                                        <div>
                                            {isNotNullAndUndefined(createComponent) && (
                                                <CreateForm
                                                    as2={createComponent}
                                                    bpTemplate={bpTemplate}
                                                    isActive
                                                    moduleName={this.props.moduleName}
                                                    onSave={(entityId, objectName) => this.props.onSave(entityId, objectName)}
                                                    onClose={() => this.props.onClose()}
                                                    onProcessStart={(processId) =>
                                                        this.props.onProcessStart(processId)
                                                    }
                                                />
                                            )}

                                        </div>
                                    )}
                            </div>
                        </div>
                    )}

                    {showCreateComponent === true && (
                        <div className="h-100">
                            {isNotNullAndUndefined(createComponent) && (
                                <CreateForm
                                    isActive
                                    as2={createComponent}
                                    initialValues={this.props.initialValues}
                                    moduleName={this.props.moduleName}
                                    onSave={(entityId, objectName) => this.props.onSave(entityId, objectName)}
                                    onClose={() => this.props.onClose()}
                                />
                            )}
                        </div>
                    )}
                    {showStartProcessContent && (
                        <DialogComponent
                            visible={showStartProcessContent}
                            cssClass="rightDialog createEditForm full-height form-center-dialog"
                            id="startBusinessProcessDialog"
                            content={this.startProcessContent.bind(this)}
                            isModal
                            target="body"
                            closeOnEscape={false}
                            close={this.startProcessDialogClose.bind(this)}
                        ></DialogComponent>
                    )}
                </div>
            );
        } else {
            return <div></div>;
        }
    }
}

export default RAFCreateFormData;
